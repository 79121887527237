import React from 'react'

const FacebookSvg = ({fill = '#cad4e0'}) => {
  return (
    <svg width="9" height="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.4062 16V8.8437H0V6h2.4062V3.75c0-1.1875.3334-2.1094 1-2.7656C4.073.328 4.9583 0 6.0625 0c.8958 0 1.625.0417 2.1875.125v2.5312h-1.5c-.5625 0-.948.125-1.1563.375-.1666.2084-.25.5417-.25 1V6H8l-.375 2.8437H5.3437V16H2.4062z"
        fill={fill}
      />
    </svg>
  )
}

export default FacebookSvg
