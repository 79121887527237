import React from 'react'

const TwitterSvg = ({fill = '#cad4e0'}) => {
  return (
    <svg width="16" height="13" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3437 3.25c.0208.0833.0313.2187.0313.4062 0 1.5-.3646 2.948-1.0938 4.3438-.75 1.4791-1.802 2.6562-3.1562 3.5312C8.6458 12.5104 6.9479 13 5.0312 13 3.198 13 1.5208 12.5104 0 11.5312c.2292.0209.4896.0313.7812.0313 1.5209 0 2.8855-.4688 4.0938-1.4063-.7292 0-1.3698-.2135-1.9219-.6406-.552-.427-.9323-.9635-1.1406-1.6094.2083.0209.4062.0313.5937.0313.2917 0 .5834-.0313.875-.0938-.75-.1666-1.375-.552-1.875-1.1562-.5-.6042-.75-1.2917-.75-2.0625v-.0625c.4584.2708.948.4167 1.4688.4375-.4375-.3125-.7917-.7083-1.0625-1.1875C.7917 3.3333.6562 2.8073.6562 2.2344c0-.573.1563-1.1198.4688-1.6407.8125 1.0209 1.8073 1.8334 2.9844 2.4375 1.177.6042 2.4323.9375 3.7656 1-.0417-.25-.0625-.5-.0625-.75 0-.5833.1458-1.1302.4375-1.6406.2916-.5104.6875-.9114 1.1875-1.2031C9.9375.1458 10.479 0 11.0625 0c.4791 0 .9218.0937 1.3281.2812a3.7113 3.7113 0 011.0781.75c.75-.1458 1.448-.4062 2.0938-.7812-.25.7708-.7292 1.375-1.4375 1.8125.625-.0833 1.25-.2604 1.875-.5313A7.2128 7.2128 0 0114.3437 3.25z"
        fill={fill}
      />
    </svg>
  )
}

export default TwitterSvg
