/* eslint-disable react/no-danger */
import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeader from '../components/Layout/PageHeader'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import {Inner} from '../components/styles'
import SocialLinks from '../components/SocialLinks'
import iconMobile from '../images/icons/icon-mobile-white.svg'
import iconLocation from '../images/icons/icon-location-white.svg'
import iconMail from '../images/icons/icon-mail-white.svg'
import CTA from '../components/shared/CTA'
import {isOnMobile} from '../utils'
import {pixelContactUs} from '../services/FacebookPixel'

const StyledContent = styled.div`
  margin-bottom: ${props => props.theme.vPadding};
`

const FormContainer = styled.div`
  background: ${props => props.theme.grey2};
  color: ${props => props.theme.white};
  margin: 0;
  padding: ${props => props.theme.vPadding} 0;
  text-align: center;
`
const ContactInformationContainer = styled.div`
  background: ${props => props.theme.grey2};
  color: ${props => props.theme.white};
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100px;
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  svg {
    fill: white;
  }
  .grid {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    max-width: ${props => props.theme.maxWidth};
    padding: 20px;
    .icon-link {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
      align-items: center;
      transition: all 200ms ease-in-out;
      color: ${props => props.theme.white};
      &:hover {
        color: ${props => props.theme.red};
      }
    }
  }
  @media (max-width: 900px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
      justify-items: left;
      .icon-link {
        grid-template-columns: 30px 1fr;
        img {
          justify-self: center;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`

const FormWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  div {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr;
    margin: 20px;
  }
  .input-row {
    display: grid;
    grid-template-columns: 180px 1fr;
    border: 2px solid ${props => props.theme.grey3};
    border-radius: 5px;
    align-content: center;
    justify-items: left;
    label {
      color: ${props => props.theme.grey1};
      padding: 10px;
      background: ${props => props.theme.grey3};
      display: block;
      width: 100%;
      text-align: left;
    }
  }
  div.form-footer {
    justify-items: center;
  }
  label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }
  input {
    letter-spacing: 0.5px;
    padding: 10px;
    background: ${props => props.theme.grey2};
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    border: none;
    color: ${props => props.theme.white};
  }
  textarea {
    letter-spacing: 0.5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid ${props => props.theme.grey3};
    background: ${props => props.theme.grey2};
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    margin: 10px auto;
    color: ${props => props.theme.white};
  }
  input:focus,
  textarea:focus {
    background: ${props => props.theme.grey1};
  }
  input::placeholder,
  textarea::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
  button {
    background: ${props => props.theme.dark};
    color: ${props => props.theme.white};
    padding: 15px 25px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    border: none;
    outline: none;
  }
  button:hover,
  button:focus {
    cursor: pointer;
    background: ${props => props.theme.red};
  }
  .textarea-row {
    border: 2px solid ${props => props.theme.grey3};
    border-radius: 5px;
    label {
      color: ${props => props.theme.grey1};
      padding: 10px;
      background: ${props => props.theme.grey3};
      display: block;
      width: 100%;
      text-align: left;
    }
  }
  textarea {
    border: none;
    margin: 0;
  }
  @media (max-width: 500px) {
    .input-row {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`

const ContactPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 935}) {
        title
        content
        acf {
          phone
          email
          location
          location_link
          location_embed
          cta_title
          cta_description
          cta_button_text
          cta_link
          info_bg_image {
            source_url
          }
        }
      }
      seo: wpgraphql {
        pageBy(pageId: 935) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  return (
    <Layout location={location} flush>
      <SEO title="About Us" location={location} seo={data.seo.pageBy.seo} />
      <PageHeader location={location}>{'Contact Us'}</PageHeader>
      <Inner>
        <StyledContent
          className="content"
          dangerouslySetInnerHTML={{__html: data.wp.content}}
        />
      </Inner>
      <ContactInformationContainer>
        <div className="grid">
          <div>
            <a
              href={`tel:${data.wp.acf.phone}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link"
            >
              <img src={iconMobile} alt="" />
              {data.wp.acf.phone}
            </a>
          </div>
          <div>
            <a
              href={`mailto:${data.wp.acf.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link"
            >
              <img src={iconMail} alt="" />
              {data.wp.acf.email}
            </a>
          </div>
          <div>
            <a
              href={data.wp.acf.location_link}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link"
            >
              <img src={iconLocation} alt="" />
              {data.wp.acf.location}
            </a>
          </div>
          <div>
            <SocialLinks svgFillColour={'white'} />
          </div>
        </div>
      </ContactInformationContainer>
      <iframe
        src={data.wp.acf.location_embed}
        width="100%"
        height="600"
        frameBorder="0"
        style={{border: 0}}
        allowFullScreen
      />
      <FormContainer>
        <Inner>
          <div className="form">
            <FormWrapper>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/contact-us/contact-request-received"
                onSubmit={pixelContactUs}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="input-row">
                  <label htmlFor="fullname">Name</label>
                  <input
                    type="text"
                    id="fullname"
                    name="Name"
                    autoFocus={isOnMobile() ? false : true}
                    required
                    placeholder="Please enter your full name"
                  />
                </div>
                <div className="input-row">
                  <label htmlFor="phone">Contact Number</label>
                  <input
                    type="tel"
                    id="phone"
                    name="Contact Number"
                    placeholder="(000) 000-0000"
                  />
                </div>
                <div className="input-row">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="Email"
                    required
                    placeholder="name@domain.com"
                  />
                </div>
                <div className="textarea-row">
                  <label htmlFor="comments">How can we help?</label>
                  <textarea
                    id="comments"
                    name="Comments"
                    required
                    placeholder="Tell us a bit more about your enquiry..."
                    rows={6}
                  />
                </div>
                <div className="form-footer">
                  <button type="submit">SEND MESSAGE</button>
                </div>
              </form>
            </FormWrapper>
          </div>
        </Inner>
      </FormContainer>
      <CTA data={data.wp.acf} />
    </Layout>
  )
}

export default ContactPage
